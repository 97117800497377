import ParseJwt from '@/utils/ParseJwt'
import { defineStore } from 'pinia'
import { computed, reactive, watch } from 'vue'

export const useIdentityStore = defineStore('IdentityStore', () => {
  const Identity: any = reactive({
    token: '',
    email: '',
    exp: 0,
    name: '',
    company: '',
    position: '',
    is_api_available: false,
    is_confirmed: false,
    is_new: false,
    is_paid: false,

    oauth_provider: null,
    popups: {},
    sub_info: {},
    surveys: {},
    user_id: '',
    credits: {}
  })
  const EmptyIdentity = Object.assign({}, Identity)

  getIdentityFromLocalStorage()

  const getIdentityIsAuthorized = computed(() => {
    return Identity.token.length > 0
  })
  const getIdentityToken = computed(() => {
    return Identity.token
  })
  const getIdentityEmail = computed(() => {
    return Identity.email
  })
  const getIdentityFullName = computed(() => {
    return Identity.name != null && Identity.name.length > 0 ? Identity.name : Identity.email
  })

  const getIdentityCompany = computed(() => {
    return Identity.company
  })
  const getIdentityPosition = computed(() => {
    return Identity.position
  })
  const getIdentityIsApiAvailable = computed(() => {
    return Identity.is_api_available
  })
  const getIdentityCredits = computed(() => {
    return Identity.credits
  })
  const getIdentitySubscription = computed(() => {
    return Identity.sub_info
  })
  const getIdentityShowIntroSurvey = computed(() => {
    return Identity.surveys.show_intro_survey
  })
  const getIdentityShowDiscountPopup = computed(() => {
    return Identity.popups?.discount_alert
  })
  const getIdentityShowDeletePopup = computed(() => {
    return Identity.popups?.deleted_account_alert
  })
  const getIdentityShowPopup = computed(() => {
    return getIdentityShowDeletePopup.value?.show
  })
  const getIdentityID = computed(() => {
    return Identity.user_id
  })
  const getIdentityIsNew = computed(() => {
    return Identity.is_new
  })
  const getIdentitySignUpType = computed(() => {
    return Identity.oauth_provider ?? 'email'
  })

  function setIdentityIsNew (): void {
    Identity.is_new = false
  }
  function setIdentity (token: string): void {
    const decoded = ParseJwt(token)
    for (const key in decoded) {
      if (key in Identity) {
        Identity[key as keyof typeof Identity] = decoded[key as keyof typeof decoded]
      }
    }
    Identity.token = token
  }
  function setBusinessInfo (info: any): void {
    Identity.position = info.position
    Identity.company = info.company
  }

  let isClearingIdentity = false
  function clearIdentity (): void {
    isClearingIdentity = true
    for (const key in Identity) {
      Identity[key] = EmptyIdentity[key]
    }
    setTimeout(() => { isClearingIdentity = false }, 10)
  }
  function logOutIdentity (): void {
    clearIdentity()
    setTimeout(() => {
      localStorage.removeItem('_xray')
      window.location.reload()
    }, 100)
  }

  // Local storage savenavButtonClick

  watch(() => Identity, (newValue, oldValue) => {
    if (!isClearingIdentity) saveIdentityToLocalStorage()
  }, { deep: true })

  function saveIdentityToLocalStorage (): void {
    const savedIdentity = JSON.stringify(Identity)
    localStorage.setItem('_xray', savedIdentity)
  }

  function getIdentityFromLocalStorage (): void {
    const identityInLocalStorage = localStorage.getItem('_xray') ?? null
    if (identityInLocalStorage !== null) {
      const parsedIdentity = JSON.parse(identityInLocalStorage)

      for (const key in parsedIdentity) {
        if (key in Identity) {
          Identity[key] = parsedIdentity[key]
        }
      }
    }
  }

  return {
    getIdentityIsAuthorized,
    getIdentityToken,
    getIdentityEmail,
    getIdentityFullName,

    getIdentityCompany,
    getIdentityPosition,
    getIdentityIsApiAvailable,
    getIdentityCredits,
    getIdentitySubscription,
    getIdentityShowIntroSurvey,
    getIdentityShowPopup,
    getIdentityShowDeletePopup,
    getIdentityShowDiscountPopup,
    getIdentityID,
    getIdentitySignUpType,
    getIdentityIsNew,
    setBusinessInfo,
    setIdentity,
    setIdentityIsNew,
    logOutIdentity
  }
})
